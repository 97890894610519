import { defineComponent, getCurrentInstance, ComponentInternalInstance, onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { getValidateCode, sendSms, checkRegisterPhone, accountLogin, initSignature } from '@/api/index'
import { Login } from '@/interface/index'
import { wxCode } from '@/utils/index'
import { SET_SIGNATURE } from '@/utils/constant'
import { Dialog } from 'vant'
import { useStore } from 'vuex'

import './index.less'

export default defineComponent({
    name: 'Login',
    setup () {
        const store = useStore()
        const { appContext } = getCurrentInstance() as ComponentInternalInstance
        const proxy = appContext.config.globalProperties
        const router = useRouter()
        const route = useRoute()
        const form: Login = {phone: '', verify: ''}
        const code = '' //验证码
        const codePic = ref('')
        const times = ref(30)
        const show = ref(true)
        


        onMounted(() => {
            onReloadCode()
            //localStorage.clear()
            // 微信验证
            wxCode()
            //微信签名
            initSignature() 
        }) 

       const onSendSms = async (data: Login) => {
           const res:any = await sendSms(data)  
           onCode() //调用倒计时
           if (res.code !== 200) return proxy.$toast(res.message);
           console.log(res, '发送短信。')
       }

        //刷新验证码
       const onReloadCode = () => {
            const path = getValidateCode + '?phone=' + form.phone + '&verify=' + Math.random()
            codePic.value = path;
        }

        const verifyUI = () => {
             //const path = onReloadCode()
             return (
                 <div>
                     <img class='tishi-icon' src='https://img.fanwoon.com/tishi.png' />
                     <img src={codePic.value} onClick={onReloadCode} />
                     <input class='code-box' v-model={code} placeholder="请输入验证码" />
                 </div>
             )
        }

        const showVerify = () => {
            Dialog.confirm({
                    message: verifyUI,
                    closeOnClickOverlay: true,
                }).then(() => {
                if (!code) {
                        return proxy.$toast('请输入图形验证码!');
                }
                
                onSendSms({phone: form.phone, verify: code})
                }).catch(() => {
                    // on cancel
                    console.log('cancel')
            });
        }

        const showConfirm = () => {
              Dialog.confirm({
                title: '该手机还未注册',
                message: '是否需要去注册该账号？',
                    closeOnClickOverlay: true,
                }).then(() => {
                    onRegister() //跳转注册
                }).catch(() => {
                    // on cancel
                    console.log('cancel')
            });
        }

        const onOpenVerify = async () => {
            if (!form.phone) return proxy.$toast('手机号不能为空!');
            const res:any = await checkRegisterPhone(form.phone)
            if (res.data === 0 ) {
                return showConfirm()
            }
            showVerify()
        }

        // 登录
        const onLogin = async () => {
           

            // 13761491853 123456  13818751493  18701872577 16602120560
            if (!form.phone) {
                return proxy.$toast("手机号不能为空！");
            }
            if (!form.verify) {
                return proxy.$toast("密码不能为空！");
            }
            const res: any = await accountLogin({account: form.phone, password: form.verify})
            if (res.code !== 0) {
                return proxy.$toast(res.message);
            }
            const local: any = localStorage.getItem(SET_SIGNATURE)
            proxy.$toast(res.message);
            console.log(res, '打印什么.')
            store.commit('setUser', res.data)
            !route.query.redirect ? router.push({path: '/personal'}) :
            router.push({path: `${route.query.redirect}`})
        }

        //注册.
        const onRegister = () => {
            router.push({path: '/register'})
        }

        //协议
        const onAreement = () => {
            router.push({path: '/areement'})
        }

        //倒计时
        const onCode = () => {
            show.value = false 
            let timer:any = setInterval(() => {
                times.value--
                if (times.value === 0) {
                    clearInterval(timer)
                    timer = null
                    show.value = true
                }
            }, 1000)
        }

        const onPwdLogin = () => {
            router.push({path: '/login'})
        }

        return () => (
            <div class="login-page">
                <div class="login-content">
                    <img class="logo" src="https://img.fanwoon.com/new_logo.png" />
                    <div class="slogan-item">门店招聘</div>
                    <div class="form-list">
                        <div class="form-list-item">
                            <div class="item-label">手机号码</div>
                            <div><input v-model={form.phone} class="input-item w498" placeholder="请输入手机号码" /></div>
                        </div>
                        <div class="form-list-item">
                            <div>
                                <div class="item-label">登录密码</div>
                                <div class="flex-box">
                                    <input v-model={form.verify } type="password" class="input-item w498" placeholder="登录密码" />
                                </div>
                            </div>
                        </div>
                        <div class="tab-font" onClick={onPwdLogin}>切换短信验证码登录</div>
                        <div>
                            <button class="button login-btn" onClick={onLogin}>登录</button>
                            <button class="register-btn" onClick={onRegister}>注册翻腕，快速招人</button>
                        </div>
                    </div>
                </div>
                <div class="login-footer">
                        登录即代表您同意<span class="privet-info" onClick={onAreement}> 《翻腕服务协议》</span>
                </div>
            </div>
        )
    }
})